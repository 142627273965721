<template>
	<v-navigation-drawer permanent color="white">
		<v-list dense nav>
			<v-list-item
				v-for="item in settingsList"
				:key="item.title"
				link
				:to="item.path"
			>
				<v-list-item-content>
					<v-list-item-title class="text-h5 font-weight-bold">{{
						item.title
					}}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>
<script>
export default {
	data() {
		return {
			settingsList: [
				{
					title: "Company Profile",
					path: "/company-profile",
					permission: "View Company Profile Page",
				},
				{
					title: "Roles and Permissions",
					path: "/roles",
					permission: "View Role",
				},
				{
					title: "Help Desk",
					permission: "View Helpdesk Page",
					path: "/helpdesk",
				},
			],
		};
	},
	mounted() {
		this.getAdminPermissions();
	},
	computed: {
		allowedSettingsList() {
			return this.settingsList.filter((item) => hasPermission(item.permission));
		},
	},
};
</script>
