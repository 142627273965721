<template>
  <!-- eslint-disable -->
  <v-container 	 class="pa-10 " id="regular-tables"  tag="section">
    <v-row>
      <v-col cols="2" class="pa-0">
<z-navigation-drawer/>
      </v-col>
      <v-col v-if="hasPermission($route.meta.permission)" class="pa-0">
        <v-card flat class="ma-0 ">
          <v-container>
            <v-row class="ma-0">
        				<v-col cols="10" sm="8" class="d-flex align-center me-auto">
									<span
										>Settings
										<v-icon>mdi-chevron-right</v-icon></span
									>
									<span class="text-sm-h2 text-h3">Roles & Permissions</span>
								</v-col>
              <v-col class="d-flex justify-center justify-sm-end">
                <v-btn v-if="hasPermission('Create Role')" class="ma-xs-0" color="primary" to="/create-role"
                  ><v-icon left color="white">mdi-plus</v-icon> New Role</v-btn
                >
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="partner_roles"
              sort-by="id"
	:options="{itemsPerPage: 20,page:1}"
              			:loading="loading"
				loading-text="Loading... Please wait"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="hasPermission('Edit Role')" small class="me-2" @click="goToEditRole(item)"> mdi-pencil </v-icon
          
                >
                <v-icon small class="me-2" @click="goToRoleDetails(item)"> mdi-eye </v-icon>
              
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
	  	<v-col v-else cols="12" class="d-flex justify-center">
				<v-card flat>
					<v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					>
				</v-card>
			</v-col>
    </v-row>
	 <!-- delete Dialog -->
    <v-dialog
      v-model="deleteDialog"
      overlay-color="munjz-accent-2"
      overlay-opacity="0.7"
      persistent
      max-width="500px"
    >
      <v-card class="rounded-lg">
 <v-container>
            <v-row class="ma-0 d-flex align-center">
              <v-col >
        <h3 >Delete Role</h3>
        </v-col>
         <v-col cols="2">
        <v-btn fab small class="blue-grey lighten-4 float-end" text @click.stop="deleteDialog = false"><v-icon>mdi-close</v-icon></v-cio></v-btn>
         </v-col>
        <v-card-text>
          <b
            >Are you sure you want to delete this role</b
          ></v-card-text
        >
              </v-col>
                  <v-col cols="12">
                   <v-btn color="red" ><v-icon>mdi-delete-outline</v-icon>Delete role</v-btn>
				   <v-btn  @click="deleteDialog=false"><v-icon>mdi-close</v-icon>cancel</v-btn>
                   </v-col>
                   </v-row>
               
                 </v-row>
          </v-container>
      </v-card>
    </v-dialog>
  </v-container>
		
</template>
<script>
import ZNavigationDrawer from "../components/core/ZNavigationDrawer.vue";
export default {
	components: { ZNavigationDrawer },
	data() {
		return {
			deleteDialog: false,
			loading: true,
			user_id: localStorage.getItem("userid"),
			token: localStorage.getItem("token"),
			partner_roles: [],
			headers: [
				{
					text: "Roles",
					value: "name"
				},
				{
					text: "Users",
					value: "users_count",
					align: "center"
				},
				{
					text: "Summary",
					value: "description",
					sortable: false
				},
				{
					text: "Actions",
					value: "actions",
					sortable: false,
					align: "center"
				}
			],
			settingsList: [
				{ title: "Company Profile", path: "/company-profile" },
				{ title: "Roles and Permissions", path: "/roles-list" },
				{ title: "Help Desk", path: "/helpdesk" }
			]
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.getAdminPermissions();
		this.getPartnerRoles();
	},
	created() {},
	methods: {
		createRole() {
			this.$router.push({
				path: `/create-role`
			});
		},
		goToEditRole(item) {
			this.$router.push({
				path: `/edit-role/${item.id}`
			});
		},
		goToRoleDetails(item) {
			this.$router.push({
				path: `/role-details/${item.id}`
			});
		},
		getPartnerRoles() {
			//  alert("user"+this.user_id)
			axios
				.get("/api/v3/b2b/roles/list", {
					headers: { "X-Authorization": this.token },
					params: {}
				})
				.then(res => {
					this.partner_roles = res.data.partner_roles;
				})
				.catch(err => console.log(err))
				.finally(() => (this.loading = false));
		}
	}
};
</script>
<style>
.v-data-table table th {
	font-size: 18px !important;
	font-weight: bolder !important;
}
</style>
